import * as React from 'react';
import IconButton from "@material-ui/core/IconButton";
import {Lock, LockOpen} from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import {useMutation} from "@apollo/react-hooks";
import {client} from "../ApolloLayer";
import {LOCK_RECIPE} from "../utilities/Queries";

export const RecipeLock = ({recipe, isEditable}) => {
    const [lockRecipe] = useMutation(LOCK_RECIPE, {
        client: client
    });

    const save = () => {
        lockRecipe({
            variables: {
                recipeId: recipe.id,
                lock: isEditable
            },
            optimisticResponse: {
                __typename: "Mutation",
                lockRecipe: {
                    __typename: "Recipe",
                    id: recipe.id,
                    isLocked: isEditable
                }
            }
        }).catch(error => {
            console.error("Error locking recipe:", error);
        });
    }

    return (
        <Tooltip title={isEditable ? "Lock for edits" : "Unlock for edits"}>
            <IconButton color={"secondary"} style={{marginLeft: "8px"}} onClick={save}>
                {isEditable ? <LockOpen/> : <Lock/>}
            </IconButton>
        </Tooltip>
    );
};