import * as React from 'react';
import {Chip} from "@material-ui/core";
import {client} from "../../ApolloLayer";
import {useMutation} from "@apollo/react-hooks";
import {SET_PREFERRED_SORTING_STRATEGY} from "../../utilities/Queries";

type Props = {
    chips: Array<{ key: SORTING_STRATEGY_KEY, selected: boolean }>;
};

export enum SORTING_STRATEGY_KEY {
    DEFAULT = 'DEFAULT',
    EXHAUST_INGREDIENTS = 'EXHAUST_INGREDIENTS',
    CALORIES = 'CALORIES',
    TOTAL_CALORIES = 'TOTAL_CALORIES'
}

export const SortingStrategy = ({chips}: Props) => {
    const [setPreferredSortingStrategy] = useMutation(SET_PREFERRED_SORTING_STRATEGY, {
        client: client,
    });
    return (
        <div className="filter-chips">
            {chips.map((chip) => {
                let isSelected = chip.selected;
                return (
                    <Chip
                        key={"sorting-chip-" + chip.key}
                        label={chip.key === SORTING_STRATEGY_KEY.DEFAULT ? "Default" : chip.key === SORTING_STRATEGY_KEY.CALORIES ? "Lowest caloric content (per sip)" : chip.key === SORTING_STRATEGY_KEY.TOTAL_CALORIES ? "Lowest caloric content (per cocktail)" : chip.key === SORTING_STRATEGY_KEY.EXHAUST_INGREDIENTS ? "Exhaust prioritized ingredients" : "Unknown"}
                        variant={isSelected ? "default" : "outlined"}
                        color="secondary"
                        style={isSelected ? {margin: "5px", color: "white"} : {margin: "5px"}}
                        onClick={() => {
                            client.cache.modify({
                                id: `ROOT_QUERY`,
                                fields: {
                                    ["selectedSortingStrategy"](existingData = {}) {
                                        return chip.key;
                                    }
                                }
                            });
                            setPreferredSortingStrategy({
                                variables: {
                                    sortingStrategy: chip.key
                                }
                            })
                        }}
                    />
                );
            })}
        </div>
    );
};