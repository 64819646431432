import React, {useState} from "react";
import {Box, IconButton, MenuItem, TextField} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {Save} from "@material-ui/icons";
import {useQuery} from "@apollo/react-hooks";
import {GET_ALL_INGREDIENT_NAMES} from "../utilities/Queries";
import {client} from "../ApolloLayer";

type Props = {
    initialAmount?: string;
    initialTypeOfMeasurement?: string;
    initialIngredientName?: string;
    onSave: (amount: string, typeOfMeasurement: string, ingredientName: string) => void;
    onCancel: () => void;
};

const EditIngredient: React.FC<Props> = ({
                                             initialAmount = "",
                                             initialTypeOfMeasurement = "oz",
                                             initialIngredientName = "",
                                             onSave,
                                             onCancel
                                         }) => {
    const [amount, setAmount] = useState(initialAmount);
    const [typeOfMeasurement, setTypeOfMeasurement] = useState(initialTypeOfMeasurement);
    const [ingredientName, setIngredientName] = useState(initialIngredientName);

    const {loading, error, data} = useQuery(GET_ALL_INGREDIENT_NAMES, {
        client: client
    });

    let ingredients: any[] = [];
    if (data && data.ingredients) {
        ingredients = [...data.ingredients];
        ingredients?.sort((ingredient1, ingredient2) => {
            return ingredient2.recipeCount.total - ingredient1.recipeCount.total;
        });
        ingredients = ingredients.filter(ingredient => ingredient.recipeCount.total > 5).map((ingredient) => ingredient.name);
    }

    const allowSave = ingredientName !== "";

    const handleKeyDown = (event) => {
        if (event.key === 'Escape') {
            onCancel();
        } else if (event.key === 'Enter' && allowSave) {
            onSave(amount, typeOfMeasurement, ingredientName);
        }
    };

    return (
        <Box style={{display: "flex", flexDirection: "row", alignItems: "center", width: "100%"}}>
            <Box style={{width: "calc(100% - 48px)"}}>
                <Box style={{marginBottom: "8px", display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <TextField
                        color="secondary"
                        type="number"
                        label="Amount"
                        onKeyDown={handleKeyDown}
                        onChange={(event) => setAmount(event.target.value)}
                        value={amount}
                        autoFocus
                        style={{width: "80px"}}
                    />
                    <TextField
                        color="secondary"
                        select
                        label="Measurement"
                        InputLabelProps={{shrink: true}}
                        style={{marginLeft: "16px", width: "120px"}}
                        value={typeOfMeasurement}
                        onKeyDown={handleKeyDown}
                        onChange={(event) => setTypeOfMeasurement(event.target.value)}
                    >
                        <MenuItem value="">{<i>{"None"}</i>}</MenuItem>
                        <MenuItem value="oz">{"oz"}</MenuItem>
                        <MenuItem value="ml">{"ml"}</MenuItem>
                        <MenuItem value="cl">{"cl"}</MenuItem>
                        <MenuItem value="tbsp">{"Table spoon"}</MenuItem>
                        <MenuItem value="tsp">{"Tea spoon"}</MenuItem>
                        <MenuItem value="bsp">{"Bar spoon"}</MenuItem>
                        <MenuItem value="dash">{"Dash"}</MenuItem>
                        <MenuItem value="drops">{"Drop"}</MenuItem>
                        <MenuItem value="top">{"Top"}</MenuItem>
                        <MenuItem value="float">{"Float"}</MenuItem>
                        <MenuItem value="spritz">{"Spritz"}</MenuItem>
                        <MenuItem value="splash">{"Splash"}</MenuItem>
                        <MenuItem value="rinse">{"Rinse"}</MenuItem>
                        <MenuItem value="sprigs">{"Sprigs"}</MenuItem>
                        <MenuItem value="leaves">{"Leaves"}</MenuItem>
                        <MenuItem value="wedges">{"Wedges"}</MenuItem>
                        <MenuItem value="g">{"g"}</MenuItem>
                    </TextField>
                </Box>
                <Autocomplete
                    options={ingredients}
                    getOptionLabel={(option) => option}
                    defaultValue={initialIngredientName ? initialIngredientName : undefined}
                    onChange={(event, value) => setIngredientName(value ? value : "")}
                    onKeyDown={(event) => {
                        if (!event.defaultPrevented) {
                            handleKeyDown(event);
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Ingredient"
                            color="secondary"
                        />
                    )}
                />
            </Box>
            <IconButton disabled={!allowSave} color="secondary" aria-label="Add"
                        onClick={() => onSave(amount, typeOfMeasurement, ingredientName)}>
                <Save/>
            </IconButton>
        </Box>
    );
};

export default EditIngredient;