import React, {useState} from "react";
import {useMutation} from "@apollo/react-hooks";
import {SAVE_INSTRUCTIONS} from "../utilities/Queries";
import {Box, Button, IconButton, TextField, Typography} from "@material-ui/core";
import {Edit, Save} from "@material-ui/icons";
import {client} from "../ApolloLayer";
import RecipeInstruction from "./RecipeInstruction";

interface RecipeInstructionsProps {
    recipe: any;
    isEditable: boolean;
}

const RecipeInstructions: React.FC<RecipeInstructionsProps> = ({recipe, isEditable}) => {
    const [editInstructions, setEditInstructions] = useState(false);
    const [editedInstructions, setEditedInstructions] = useState(recipe.instructions);
    const [saveInstructions] = useMutation(SAVE_INSTRUCTIONS, {
        client: client
    });

    let instructions = recipe.instructions;
    let split = instructions.split(".");
    let removed: number[] = [];
    for (let i = 0; i < split.length; i++) {
        let instruction = split[i];
        if (instruction.toLowerCase().endsWith(" st")) {
            split[i] += "." + split[i + 1];
            i++;
            removed.push(i);
        }
    }
    removed.forEach((removedNumber) => {
        split.splice(removedNumber, 1);
    });

    function handleSave() {
        saveInstructions({
            variables: {recipeId: recipe.id, instructions: editedInstructions},
            optimisticResponse: {
                __typename: "Mutation",
                saveInstructions: {
                    __typename: "Recipe",
                    id: recipe.id,
                    instructions: editedInstructions,
                    postDilutionAbv: recipe.postDilutionAbv,
                    alcoholicUnits: recipe.alcoholicUnits,
                    postDilutionVolume: recipe.postDilutionVolume,
                    cocktailCategory: recipe.cocktailCategory,
                    mixingTechnique: recipe.mixingTechnique
                }
            }
        });
        setEditInstructions(false);
    }

    if (editInstructions) {
        const handleKeyDown = (event) => {
            if (event.key === "Enter" && event.ctrlKey) {
                handleSave();
            }
            if (event.key === "Escape") {
                setEditInstructions(false);
                setEditedInstructions(instructions);
            }
        };

        return (
            <div className={"flex-column"} style={{marginBottom: "8px"}}>
                <Typography color="secondary" variant="h6">{"Instructions"}</Typography>
                <div className={"flex-row-center"}>
                    <TextField
                        multiline
                        rowsMax={8}
                        defaultValue={instructions}
                        onChange={(event) => {
                            setEditedInstructions(event.target.value);
                        }}
                        onKeyDown={handleKeyDown}
                        color="secondary"
                        style={{width: "100%"}}
                    />
                    <IconButton color="secondary" aria-label="Save instructions"
                                onClick={handleSave}>
                        <Save/>
                    </IconButton>
                </div>
            </div>
        );
    }

    const hasInstructions = recipe.instructions !== null && recipe.instructions.trim() !== "";
    const instructionsUi = <div>
        {hasInstructions ? split.map((instruction, i) => {
            return <RecipeInstruction key={i} instruction={instruction} index={i}/>;
        }) : <Typography variant={"body2"} style={{fontStyle: "italic", marginTop: "8px", marginBottom: "16px"}}>
            {"No instructions provided."}
        </Typography>}
    </div>;

    if (isEditable && hasInstructions) {
        return (
            <div className="flex-column-center">
                <div className={"flex-row-center"}>
                    <Typography color="secondary" variant="h6">{"Instructions"}</Typography>
                    <IconButton color="secondary" aria-label="Edit instructions"
                                onClick={() => setEditInstructions(true)}>
                        <Edit/>
                    </IconButton>
                </div>
                {instructionsUi}
            </div>
        );
    }
    if (isEditable) {
        return (
            <>
                <Button onClick={() => setEditInstructions(true)} style={{
                    borderRadius: 4,
                    margin: "10px",
                    padding: "8px",
                    border: "1px solid #d4af37",
                    backgroundColor: "rgba(212, 175, 55, 0.1)",
                    textTransform: "none",
                    transition: "background-color 0.3s",
                    width: "calc(100% - 20px)",
                    height: "150px"
                }}
                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "rgba(212, 175, 55, 0.5)"}
                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "rgba(212, 175, 55, 0.1)"}>
                    <Box>
                        <Typography variant={"body2"} style={{fontStyle: "italic"}}>
                            {"Add instructions here to specify how to make the cocktail."}
                        </Typography>
                    </Box>
                </Button>
            </>
        );
    }
    return (
        <div className="flex-column-center">
            <Typography color="secondary" variant="h6">{"Instructions"}</Typography>
            {instructionsUi}
        </div>
    );
};

export default RecipeInstructions;