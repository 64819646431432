import * as React from 'react';
import {
    AppBar,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Toolbar,
    Typography,
    useMediaQuery
} from "@material-ui/core";
import {Add, CheckCircle, Close} from "@material-ui/icons";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {
    ADD_INGREDIENT_TO_PANTRY_PROFILE,
    CREATE_NEW_PANTRY_PROFILE,
    GET_PANTRY_PROFILES,
    REMOVE_INGREDIENT_FROM_PANTRY_PROFILE,
} from "../../utilities/Queries";
import {client} from "../../ApolloLayer";
import {Ingredient} from "./SingleIngredient";

type Props = {
    ingredient: Ingredient;
    open: boolean;
    onClose: () => void;
};

export const IngredientPantryProfileDialog = ({ingredient, open, onClose}: Props) => {
    const {loading, error, data} = useQuery(GET_PANTRY_PROFILES, {
        client: client,
    });

    const isLargeScreen = useMediaQuery("(min-width:950px)");

    const [createNewPantryProfile] = useMutation(CREATE_NEW_PANTRY_PROFILE, {
        client: client,
        update(cache, {data: {createPantryProfile}}) {
            const existingProfiles: any = cache.readQuery({query: GET_PANTRY_PROFILES});
            cache.writeQuery({
                query: GET_PANTRY_PROFILES,
                data: {
                    pantryProfiles: [
                        ...existingProfiles.pantryProfiles,
                        createPantryProfile,
                    ],
                },
            });
        },
        optimisticResponse: {
            createPantryProfile: {
                __typename: "PantryProfile",
                id: Math.random().toString(36).substr(2, 9),
                title: ingredient.name,
                active: true,
                activeUntil: null,
                ingredients: [
                    {
                        __typename: "PantryProfileIngredient",
                        id: Math.random().toString(36).substr(2, 9),
                        name: ingredient.name,
                    },
                ],
            },
        },
    });

    const [addIngredientToPantryProfile] = useMutation(ADD_INGREDIENT_TO_PANTRY_PROFILE, {
        client: client,
        update(cache, {data: {addIngredientToPantryProfile}}) {
            const existingProfiles: any = cache.readQuery({query: GET_PANTRY_PROFILES});
            cache.writeQuery({
                query: GET_PANTRY_PROFILES,
                data: {
                    pantryProfiles: existingProfiles.pantryProfiles.map((profile: any) =>
                        profile.id === addIngredientToPantryProfile.id ? addIngredientToPantryProfile : profile
                    ),
                },
            });
        },
        optimisticResponse: ({pantryProfileId, ingredient}) => ({
            addIngredientToPantryProfile: {
                __typename: "PantryProfile",
                id: pantryProfileId,
                title: data.pantryProfiles.find((p: any) => p.id === pantryProfileId).title,
                ingredients: [
                    ...data.pantryProfiles.find((p: any) => p.id === pantryProfileId).ingredients,
                    {
                        __typename: "PantryProfileIngredient",
                        id: Math.random().toString(36).substr(2, 9),
                        name: ingredient,
                    },
                ],
            },
        }),
    });

    const [removeIngredientFromPantryProfile] = useMutation(REMOVE_INGREDIENT_FROM_PANTRY_PROFILE, {
        client: client,
        update(cache, {data: {removeIngredientFromPantryProfile}}) {
            const existingProfiles: any = cache.readQuery({query: GET_PANTRY_PROFILES});
            cache.writeQuery({
                query: GET_PANTRY_PROFILES,
                data: {
                    pantryProfiles: existingProfiles.pantryProfiles.map((profile: any) =>
                        profile.id === removeIngredientFromPantryProfile.id ? removeIngredientFromPantryProfile : profile
                    ),
                },
            });
        },
        optimisticResponse: ({pantryProfileId, ingredient}) => ({
            removeIngredientFromPantryProfile: {
                __typename: "PantryProfile",
                id: pantryProfileId,
                title: data.pantryProfiles.find((p: any) => p.id === pantryProfileId).title,
                ingredients: data.pantryProfiles
                    .find((p: any) => p.id === pantryProfileId)
                    .ingredients.filter((ing: any) => ing.name !== ingredient),
            },
        }),
    });

    const handleToggleIngredient = (profileId: string, containsIngredient: boolean) => {
        if (containsIngredient) {
            removeIngredientFromPantryProfile({
                variables: {pantryProfileId: profileId, ingredient: ingredient.name},
            });
        } else {
            addIngredientToPantryProfile({
                variables: {pantryProfileId: profileId, ingredient: ingredient.name},
            });
        }
    };

    const handleCreateNewProfile = () => {
        createNewPantryProfile({
            variables: {title: ingredient.name, ingredient: ingredient.name},
        });
    };

    if (loading) return <Dialog open={open}><DialogTitle>{"Loading..."}</DialogTitle></Dialog>;
    if (error) {
        console.log(error);
        return <Dialog open={open}><DialogTitle>{"Error loading list of temporary ingredients"}</DialogTitle></Dialog>
    }

    const reversedProfiles = [...data.pantryProfiles].reverse();
    const matchingProfile = data.pantryProfiles.find(
        (profile: any) => profile.title === ingredient.name && profile.ingredients.length === 1 && profile.ingredients[0].name === ingredient.name
    );

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth fullScreen={!isLargeScreen}>
            {!isLargeScreen && (
                <AppBar color="secondary" style={{position: "relative"}}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                            <Close color="primary"/>
                        </IconButton>
                        <Typography color="primary" variant="h6">
                            {"Add \"" + ingredient.name + "\" as a temporary ingredient"}
                        </Typography>
                    </Toolbar>
                </AppBar>
            )}
            {isLargeScreen && <DialogTitle>{"Add \"" + ingredient.name + "\" as a temporary ingredient"}</DialogTitle>}
            <DialogContent>
                <DialogContentText>
                    {"Add \"" + ingredient.name + "\" as a temporary ingredient, or combine it with an existing list of temporary ingredients."}
                </DialogContentText>
                <List>
                    {/* Add New Pantry Profile Button */}
                    {!matchingProfile && (
                        <ListItem button onClick={handleCreateNewProfile}>
                            <ListItemIcon>
                                <Add/>
                            </ListItemIcon>
                            <ListItemText primary={"Add as temporary ingredient"}/>
                        </ListItem>
                    )}

                    {/* Existing Pantry Profiles */}
                    {reversedProfiles.map((profile: any) => {
                        const containsIngredient = profile.ingredients.some(
                            (ing: any) => ing.name === ingredient.name
                        );

                        return (
                            <ListItem
                                key={profile.id}
                                button
                                onClick={() => handleToggleIngredient(profile.id, containsIngredient)}
                            >
                                <ListItemIcon>
                                    <CheckCircle color={containsIngredient ? "secondary" : "inherit"}/>
                                </ListItemIcon>
                                <ListItemText primary={profile.title + " (" + profile.ingredients.length + " ingredients)"}/>
                            </ListItem>
                        );
                    })}
                </List>
            </DialogContent>
        </Dialog>
    );
};
