import React, {useState} from "react";
import {
    AppBar,
    Avatar,
    Chip,
    Dialog,
    DialogTitle,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    TextField,
    Toolbar,
    Typography
} from "@material-ui/core";
import {Add, Clear, Close, Done, LocalOffer} from "@material-ui/icons";
import {useMutation} from "@apollo/react-hooks";
import {ADD_TAG, DELETE_TAG, GET_RECIPE_FULL} from "../utilities/Queries";
import {client} from "../ApolloLayer";

interface RecipeTagsProps {
    recipe: any;
    classes: any;
    getRecipeFullVariables: any;
    isMobile: boolean;
    isLoggedIn: boolean;
    isEditable: boolean;
    allTags: any[];
}

const RecipeTags: React.FC<RecipeTagsProps> = ({
                                                   recipe,
                                                   classes,
                                                   getRecipeFullVariables,
                                                   isMobile,
                                                   isLoggedIn,
                                                   isEditable,
                                                   allTags
                                               }) => {
    if (!isLoggedIn || isEditable) {
        return undefined;
    }

    const [isOpen, setOpen] = useState(false);
    const [newTagText, setNewTagText] = useState<string | null>(null);

    const [deleteTag] = useMutation(DELETE_TAG, {client: client});
    const [addTag] = useMutation(ADD_TAG, {client: client});

    const handleDeleteTag = (tag: any) => {
        let newTags = recipe.tags.filter((innerTag: any) => tag.id !== innerTag.id).map((tag: any) => ({
            __typename: "Tag",
            id: tag.id,
            name: tag.name
        }));
        deleteTag({
            variables: {recipeId: recipe.id, tag: tag.name},
            refetchQueries: [{query: GET_RECIPE_FULL, variables: getRecipeFullVariables}],
            optimisticResponse: {
                __typename: "Mutation",
                deleteTag: {
                    __typename: "Recipe",
                    id: recipe.id,
                    tags: newTags
                }
            }
        });
    };

    const handleAddTag = () => {
        if (newTagText !== null && newTagText !== "") {
            let newTags = recipe.tags.map((tag: any) => ({__typename: "Tag", id: tag.id, name: tag.name}));
            newTags.push({__typename: "Tag", id: newTagText, name: newTagText});
            addTag({
                variables: {recipeId: recipe.id, tag: newTagText},
                refetchQueries: [{query: GET_RECIPE_FULL, variables: getRecipeFullVariables}],
                optimisticResponse: {
                    __typename: "Mutation",
                    addTag: {
                        __typename: "Recipe",
                        id: recipe.id,
                        tags: newTags
                    }
                }
            });
            setNewTagText(null);
            setOpen(false);
        } else {
            setNewTagText(null);
        }
    };

    const renderAddNewTag = () => (
        <>
            {newTagText === null ? (
                <ListItem button onClick={() => setNewTagText("")}>
                    <ListItemAvatar>
                        <Avatar className={classes.avatar}>
                            <Add/>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Add new tag"/>
                </ListItem>
            ) : (
                <ListItem>
                    <ListItemAvatar>
                        <IconButton aria-label="Cancel" onClick={() => setNewTagText(null)}>
                            <Clear color="secondary"/>
                        </IconButton>
                    </ListItemAvatar>
                    <ListItemText primary={
                        <TextField autoFocus defaultValue={newTagText}
                                   onChange={(event) => setNewTagText(event.target.value)} onKeyDown={(event) => {
                            if (event.keyCode === 13) {
                                handleAddTag();
                                event.preventDefault();
                            }
                        }} color="secondary"/>
                    }/>
                    <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="Done" onClick={handleAddTag}>
                            <Done color="secondary"/>
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
            )}
        </>
    );

    const renderTagDialog = () => {
        let content = (
            <List>
                {renderAddNewTag()}
                {allTags.filter(tag => recipe.tags.findIndex(recipeTag => recipeTag.name === tag.name) === -1).map((tag) => (
                    <ListItem button onClick={() => {
                        let newTags = recipe.tags.map((tag: any) => ({__typename: "Tag", id: tag.id, name: tag.name}));
                        newTags.push({__typename: "Tag", id: tag.name, name: tag.name});
                        addTag({
                            variables: {recipeId: recipe.id, tag: tag.name},
                            refetchQueries: [{query: GET_RECIPE_FULL, variables: getRecipeFullVariables}],
                            optimisticResponse: {
                                __typename: "Mutation",
                                addTag: {
                                    __typename: "Recipe",
                                    id: recipe.id,
                                    tags: newTags
                                }
                            }
                        });
                        setOpen(false);
                    }} key={tag.name}>
                        <ListItemAvatar>
                            <Avatar className={classes.avatarPersonal}>
                                <LocalOffer/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={tag.name}/>
                    </ListItem>
                ))}
            </List>
        );

        if (isMobile) {
            return (
                <Dialog fullScreen open={isOpen}>
                    <AppBar color="secondary" style={{position: "relative"}}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={() => setOpen(false)} aria-label="close">
                                <Close color="primary"/>
                            </IconButton>
                            <Typography color="primary" variant="h6" className={classes.title}>
                                Add tags
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    {content}
                </Dialog>
            );
        } else {
            return (
                <Dialog onClose={() => setOpen(false)} open={isOpen}>
                    <DialogTitle id="simple-dialog-title">Add tags</DialogTitle>
                    {content}
                </Dialog>
            );
        }
    };

    return (
        <div className="flex-column-center">
            <div className="flex-row-center">
                <Typography color="secondary" variant="h6">Tags</Typography>
                <IconButton color="secondary" aria-label="Add" onClick={() => setOpen(true)}>
                    <Add/>
                </IconButton>
            </div>
            <div className="tags">
                {recipe.tags.map((tag: any) => (
                    <Chip key={"chip-" + tag.name} label={tag.name} variant="outlined" color="secondary"
                          className={classes.chip} onDelete={() => handleDeleteTag(tag)}/>
                ))}
            </div>
            {renderTagDialog()}
        </div>
    );
};

export default RecipeTags;