import React, {useState} from "react";
import {Dialog, DialogContent, DialogTitle, IconButton, Typography} from "@material-ui/core";
import {DeleteRounded, Edit, InfoOutlined} from "@material-ui/icons";
import {getPrettyIngredientName} from "../utilities/Utilities";
import {useMutation} from "@apollo/react-hooks";
import {client} from "../ApolloLayer";
import {EDIT_INGREDIENT, REMOVE_INGREDIENT} from "../utilities/Queries";
import EditIngredient from "./EditIngredient";

function calculateAmount(recipe, ingredient, howMany) {
    if (ingredient.amount) {
        let servings = recipe.servings;
        if (servings === howMany) {
            return ingredient.amount;
        }
        return parseFloat((ingredient.amount * (howMany / servings)).toFixed(2));
    }
    return "";
}

function formatBottles(bottles) {
    if (bottles.length === 0) {
        return "";
    }
    let returnString;
    if (bottles.length === 1) {
        returnString = bottles[0];
    } else {
        let firstElements = bottles.slice(0, bottles.length - 1);
        returnString = firstElements.join(", ");
        returnString += " or " + bottles[bottles.length - 1];
    }
    return " (" + returnString + ")";
}

const RecipeIngredient = ({recipe, ingredient, howMany, classes, ingredientDescription, i, isEditable}) => {
    const [completed, setCompleted] = useState(false);
    const [editing, setEditing] = useState(-1);
    const [descriptionDialogOpen, setDescriptionDialogOpen] = useState(false);

    const [removeIngredient] = useMutation(REMOVE_INGREDIENT, {
        client: client,
        optimisticResponse: {
            removeIngredient: {
                __typename: "Recipe",
                id: recipe.id,
                ingredients: recipe.ingredients.filter((_, index) => index !== i),
                postDilutionAbv: recipe.postDilutionAbv,
                alcoholicUnits: recipe.alcoholicUnits,
                postDilutionVolume: recipe.postDilutionVolume,
                cocktailCategory: recipe.cocktailCategory
            }
        }
    });

    const [editIngredient] = useMutation(EDIT_INGREDIENT, {
        client: client
    });

    const renderDividerLine = i !== 0;

    let amount = calculateAmount(recipe, ingredient, howMany);
    let amountWithMeasurement;
    if (amount === 0) {
        amountWithMeasurement = ingredient.typeOfMeasurement;
    } else if (!ingredient.typeOfMeasurement || ingredient.typeOfMeasurement === "null") {
        amountWithMeasurement = amount;
    } else {
        amountWithMeasurement = amount + " " + ingredient.typeOfMeasurement;
    }

    const handleEditOpen = () => {
        setEditing(i);
    }

    const handleDelete = () => {
        removeIngredient({
            variables: {
                recipeId: recipe.id,
                ingredient: ingredient.id,
                index: i
            }
        });
    }

    const save = (amount, typeOfMeasurement, ingredientName) => {
        editIngredient({
            variables: {
                recipeId: recipe.id,
                ingredientIndex: i,
                ingredient: ingredientName,
                amount: parseFloat(amount),
                typeOfMeasurement: typeOfMeasurement
            },
            optimisticResponse: {
                editIngredient: {
                    __typename: "Recipe",
                    id: recipe.id,
                    ingredients: recipe.ingredients.map((ingredient, index) => {
                        if (index === i) {
                            return {
                                __typename: "RecipeIngredient",
                                id: ingredientName,
                                name: ingredientName,
                                amount: parseFloat(amount),
                                typeOfMeasurement: typeOfMeasurement,
                                bottles: ingredient.bottles
                            };
                        }
                        return ingredient;
                    }),
                    postDilutionAbv: recipe.postDilutionAbv,
                    alcoholicUnits: recipe.alcoholicUnits,
                    postDilutionVolume: recipe.postDilutionVolume,
                    cocktailCategory: recipe.cocktailCategory
                }
            }
        }).catch(error => {
            console.error("Error adding ingredient:", error);
        });
        cancelSave();
    }

    const cancelSave = () => {
        setEditing(-1);
    }

    if (editing !== -1) {
        return <EditIngredient onSave={save}
                               onCancel={cancelSave}
                               initialAmount={amount}
                               initialTypeOfMeasurement={ingredient.typeOfMeasurement}
                               initialIngredientName={ingredient.name}/>;
    }

    return (
        <>
            <div key={ingredient.id + i}
                 style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}
                 className={renderDividerLine ? "ingredient-with-divider" : "ingredient-without-divider"}>
                <div key={ingredient.id}
                     onClick={() => {
                         setCompleted(!completed);
                     }}
                     style={completed ? {
                         cursor: "pointer",
                         textDecoration: "line-through",
                         display: "flex",
                         flexDirection: "row",
                         alignItems: "center",
                         width: "calc(100% - 48px)",
                         padding: "12px"
                     } : {
                         cursor: "pointer",
                         display: "flex",
                         flexDirection: "row",
                         alignItems: "center",
                         width: "calc(100% - 48px)",
                         padding: "12px"
                     }}>
                    <Typography key={ingredient.id} variant="body2" className={classes.ingredientAmount}>
                        {amountWithMeasurement}
                    </Typography>
                    <Typography variant="body2">
                        {getPrettyIngredientName(ingredient.name) + formatBottles(ingredient.bottles)}
                    </Typography>
                </div>
                {isEditable && (
                    <IconButton color="secondary"
                                aria-label="Edit ingredient"
                                onClick={handleEditOpen}>
                        <Edit/>
                    </IconButton>
                )}
                {isEditable && (
                    <IconButton color="secondary"
                                aria-label="Delete ingredient"
                                onClick={handleDelete}>
                        <DeleteRounded/>
                    </IconButton>
                )}
                {ingredientDescription && <IconButton color="secondary" aria-label="Learn more" onClick={() => {
                    setDescriptionDialogOpen(true);
                }}>
                    <InfoOutlined/>
                </IconButton>}
            </div>
            {ingredientDescription && <Dialog open={descriptionDialogOpen} onClose={() => {
                setDescriptionDialogOpen(false);
            }}>
                <DialogTitle>{getPrettyIngredientName(ingredient.name)}</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">{ingredientDescription.description}</Typography>
                </DialogContent>
            </Dialog>}
        </>
    );
};
export default RecipeIngredient;