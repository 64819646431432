import * as React from 'react';
import {useState} from 'react';
import {
    AppBar,
    Box,
    Button,
    DialogContentText,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Switch,
    TextField,
    Toolbar,
    Tooltip,
    Typography
} from "@material-ui/core";
import {ArrowBack, Delete, EditRounded, SaveRounded} from "@material-ui/icons";
import {useMutation} from "@apollo/react-hooks";
import {
    DELETE_PANTRY_PROFILE,
    GET_PANTRY_PROFILES,
    REMOVE_INGREDIENT_FROM_PANTRY_PROFILE,
    TOGGLE_PANTRY_PROFILE,
    UPDATE_PANTRY_PROFILE_TITLE
} from "../../utilities/Queries";
import {PantryProfile} from "./PantryProfiles";
import {client} from "../../ApolloLayer";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";

interface SingleProfileViewProps {
    profile: PantryProfile;
    onBack: () => void;
}

export const SingleProfileView: React.FC<SingleProfileViewProps> = ({profile, onBack}) => {
    const isLargeScreen = useMediaQuery("(min-width:950px)");
    const [title, setTitle] = useState(profile.title);
    const [editTitleEnabled, setEditTitleEnabled] = useState(false);
    const [removeIngredientFromPantryProfile] = useMutation(REMOVE_INGREDIENT_FROM_PANTRY_PROFILE, {
        client: client,
        update(cache, {data: {removeIngredientFromPantryProfile}}) {
            const existingProfiles: any = cache.readQuery({query: GET_PANTRY_PROFILES});
            cache.writeQuery({
                query: GET_PANTRY_PROFILES,
                data: {
                    pantryProfiles: existingProfiles.pantryProfiles.map((p: any) =>
                        p.id === removeIngredientFromPantryProfile.id ? removeIngredientFromPantryProfile : p
                    ),
                },
            });
        },
        optimisticResponse: ({pantryProfileId, ingredient}) => ({
            removeIngredientFromPantryProfile: {
                __typename: "PantryProfile",
                id: pantryProfileId,
                title: profile.title,
                ingredients: profile.ingredients.filter((ing) => ing.name !== ingredient),
            },
        }),
    });

    const [updatePantryProfileTitle] = useMutation(UPDATE_PANTRY_PROFILE_TITLE, {
        client: client,
        update(cache, {data: {updatePantryProfileTitle}}) {
            const existingProfiles: any = cache.readQuery({query: GET_PANTRY_PROFILES});
            cache.writeQuery({
                query: GET_PANTRY_PROFILES,
                data: {
                    pantryProfiles: existingProfiles.pantryProfiles.map((p: any) =>
                        p.id === updatePantryProfileTitle.id ? updatePantryProfileTitle : p
                    ),
                },
            });
        },
        optimisticResponse: ({id, title}) => ({
            updatePantryProfileTitle: {
                __typename: "PantryProfile",
                id: id,
                title: title,
                active: profile.active,
                activeUntil: profile.activeUntil,
                ingredients: profile.ingredients,
            },
        }),
    });

    const [togglePantryProfile] = useMutation(TOGGLE_PANTRY_PROFILE, {
        client: client,
        update(cache, {data: {togglePantryProfile}}) {
            const existingProfiles: any = cache.readQuery({query: GET_PANTRY_PROFILES});
            cache.writeQuery({
                query: GET_PANTRY_PROFILES,
                data: {
                    pantryProfiles: existingProfiles.pantryProfiles.map((p: any) =>
                        p.id === togglePantryProfile.id ? togglePantryProfile : p
                    ),
                },
            });
        },
        optimisticResponse: ({id, active}) => ({
            togglePantryProfile: {
                __typename: "PantryProfile",
                id: id,
                active: active,
                activeUntil: profile.activeUntil
            },
        }),
    });

    const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>, profileId: string) => {
        togglePantryProfile({
            variables: {id: profileId, active: event.target.checked},
        });
    };

    const handleRemoveIngredient = (ingredientName: string) => {
        removeIngredientFromPantryProfile({
            variables: {pantryProfileId: profile.id, ingredient: ingredientName},
        });
    };

    const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value);
    };

    const handleTitleSave = () => {
        updatePantryProfileTitle({
            variables: {id: profile.id, title: title},
        });
    };

    const [deletePantryProfile] = useMutation(DELETE_PANTRY_PROFILE, {
        client: client
    });

    const handleDeleteProfile = () => {
        deletePantryProfile({
            variables: {id: profile.id},
            update(cache) {
                const existingProfiles: any = cache.readQuery({query: GET_PANTRY_PROFILES});
                cache.writeQuery({
                    query: GET_PANTRY_PROFILES,
                    data: {
                        pantryProfiles: existingProfiles.pantryProfiles.filter((p: any) => p.id !== profile.id),
                    },
                });
            },
            optimisticResponse: () => ({
                deletePantryProfile: true
            }),
        });
        onBack();
    };

    return (
        <>
            {!isLargeScreen && (
                <AppBar color="secondary" style={{position: "relative"}}>
                    <Toolbar>
                        <Tooltip title="Back">
                            <IconButton edge="start" color="inherit" onClick={onBack} aria-label="back">
                                <ArrowBack color="primary"/>
                            </IconButton>
                        </Tooltip>
                        {editTitleEnabled ? (
                            <TextField
                                InputProps={{style: {color: "white"}}}
                                value={title}
                                onChange={handleTitleChange}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        handleTitleSave();
                                        setEditTitleEnabled(false);
                                    }
                                }}
                                fullWidth
                            />
                        ) : (
                            <Typography color="primary" variant="h6" style={{flexGrow: 1}}>
                                {profile?.title}
                            </Typography>
                        )}
                        <IconButton aria-label="edit"
                                    onClick={() => {
                                        if (editTitleEnabled) {
                                            handleTitleSave();
                                        }
                                        setEditTitleEnabled(!editTitleEnabled);
                                    }}>
                            {!editTitleEnabled ? <EditRounded color="primary"/> : <SaveRounded color="primary"/>}
                        </IconButton>
                    </Toolbar>
                </AppBar>
            )}
            {isLargeScreen &&
                <DialogTitle>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Tooltip title="Back">
                            <IconButton edge="start" color="inherit" onClick={onBack} aria-label="back">
                                <ArrowBack color="secondary"/>
                            </IconButton>
                        </Tooltip>
                        {editTitleEnabled ? (
                            <TextField
                                value={title}
                                onChange={handleTitleChange}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        handleTitleSave();
                                        setEditTitleEnabled(false);
                                    }
                                }}
                                fullWidth
                            />
                        ) : (
                            <Typography variant="h6">
                                {profile?.title}
                            </Typography>
                        )}
                        <IconButton aria-label="edit"
                                    onClick={() => {
                                        if (editTitleEnabled) {
                                            handleTitleSave();
                                        }
                                        setEditTitleEnabled(!editTitleEnabled);
                                    }}>
                            {!editTitleEnabled ? <EditRounded color="secondary"/> : <SaveRounded color="secondary"/>}
                        </IconButton>
                    </Box>
                </DialogTitle>}
            <DialogContent>
                {profile && (
                    <>
                        <DialogContentText>
                            {"Use this menu to manage the ingredients in your list. If you want to add ingredients, close this dialog and use the \"Add temporarily\" button on an ingredient you do not own."}
                        </DialogContentText>
                        <Box display={"flex"} flexDirection={"row"} alignItems={"center"}
                             justifyContent={"space-between"} style={{border: "1px solid #d4af37"}} my={1} p={1}>
                            <DialogContentText style={{marginBottom: 0}}>
                                {"Toggle whether this list is active. When active, the ingredients below will be temporarily included as owned ingredients, resulting in more recipe suggestions that you can make."}
                            </DialogContentText>
                            <Divider orientation="vertical" flexItem
                                     style={{backgroundColor: "#d4af37", marginBottom: "-8px", marginTop: "-8px"}}/>
                            <Box ml={0.5}>
                                <Switch
                                checked={profile.active}
                                onChange={(event) => handleToggleChange(event, profile.id)}
                                color="secondary"
                                inputProps={{'aria-label': 'Enable/Disable list of temporary ingredients'}}
                                />
                            </Box>
                        </Box>
                        <List>
                            {profile.ingredients.map((ingredient) => (
                                <ListItem key={ingredient.id}>
                                    <ListItemText primary={ingredient.name}/>
                                    <Tooltip title={"Remove from the list"}>
                                        <ListItemIcon>
                                            <IconButton edge="end" aria-label="delete"
                                                        onClick={() => handleRemoveIngredient(ingredient.name)}>
                                                <Delete color="secondary"/>
                                            </IconButton>
                                        </ListItemIcon>
                                    </Tooltip>
                                </ListItem>
                            ))}
                        </List>
                        <Box display="flex" justifyContent="flex-end" mt={2}>
                            <Button onClick={handleDeleteProfile} style={{backgroundColor: "#cc3300", color: "white"}}
                                    variant="contained">
                                {"Delete list"}
                            </Button>
                        </Box>
                    </>
                )}
            </DialogContent>
        </>
    );
};