import React from 'react';
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import {useRecipeCardStyles} from "./RecipesCard";
import {AppBar, Box, CardActionArea, TextField, Toolbar} from "@material-ui/core";
import {Add, Close} from "@material-ui/icons";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import DialogContent from "@material-ui/core/DialogContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import {useMutation} from '@apollo/client';
import {CREATE_RECIPE} from '../utilities/Queries';
import {useNavigate} from 'react-router-dom';
import {client} from "../ApolloLayer";

export const CreateRecipeCard = () => {
    const isLargeScreen = useMediaQuery("(min-width:950px)");
    const classes = useRecipeCardStyles();
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [title, setTitle] = React.useState("");
    const navigate = useNavigate();

    const [createRecipe] = useMutation(CREATE_RECIPE, {
        onCompleted: (data) => {
            setDialogOpen(false);
            setTitle("");
            navigate(`/recipe/${data.createRecipe.id}`);
            client.cache.evict({id: "ROOT_QUERY", fieldName: "recipes"});
            client.cache.evict({id: "ROOT_QUERY", fieldName: "searchItems"});
            client.cache.gc(); // Run garbage collection to clean up evicted data
        },
        onError: (error) => {
            console.error("Error creating recipe:", error);
        }
    });

    function onClose() {
        setDialogOpen(false);
        setTitle("");
    }

    const handleCreateRecipe = () => {
        createRecipe({variables: {title}});
    };

    return (
        <>
            <div className={"recipe-card"} style={{minHeight: "234px"}}>
                <Card className={classes.root2}>
                    <Box style={{width: "100%", height: "100%"}}>
                        <CardActionArea style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%"
                        }} onClick={() => setDialogOpen(true)}>
                            <Add color={"secondary"} style={{fontSize: 64}}/>
                            <Typography variant="h5" color={"secondary"} component="p">
                                {"Create a new cocktail"}
                            </Typography>
                        </CardActionArea>
                    </Box>
                </Card>
            </div>
            <Dialog open={dialogOpen}
                    fullScreen={!isLargeScreen}
                    onClose={onClose}
                    fullWidth={true}
            >
                {!isLargeScreen && (
                    <AppBar color="secondary" style={{position: "relative"}}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={onClose}
                                        aria-label="close">
                                <Close color="primary"/>
                            </IconButton>
                            <Typography color="primary" variant="h6">
                                {"Create a new cocktail"}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                )}
                {isLargeScreen && <DialogTitle>{"Create a new cocktail"}</DialogTitle>}
                <DialogContent>
                    <Typography>
                        {"To start creating your cocktail, please give it a name."}
                    </Typography>
                    <TextField color={"secondary"}
                               fullWidth={true}
                               onChange={(event) => setTitle(event.target.value)}
                               label="Title"
                               variant="standard"/>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant={"text"}
                        onClick={onClose}
                        style={{color: "#cc3300"}}
                    >
                        {"Cancel"}
                    </Button>
                    <Button disabled={!title.trim()} variant={"contained"} color={"secondary"}
                            onClick={handleCreateRecipe}>{"Create"}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};