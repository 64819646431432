import React, {useState} from "react";
import Typography from "@material-ui/core/Typography";
import {Box, Button, TextField} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {Edit} from "@material-ui/icons";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {useMutation} from "@apollo/client";
import {SAVE_GLASS} from "../utilities/Queries";

function RecipeGlass({recipe, classes, isEditable}) {
    const [isEditing, setIsEditing] = useState(false);
    const [editedGlass, setEditedGlass] = useState(recipe.glass);
    const [saveGlass] = useMutation(SAVE_GLASS, {
        optimisticResponse: {
            saveGlass: {
                __typename: "Recipe",
                id: recipe.id,
                glass: editedGlass,
            },
        },
    });

    const handleSave = () => {
        saveGlass({variables: {recipeId: recipe.id, glass: editedGlass}})
            .catch(error => {
                console.error("Error saving glass:", error);
            });
        setIsEditing(false);
    };

    const handleClose = () => {
        setIsEditing(false);
        setEditedGlass(recipe.glass);
    };

    return (
        <Box style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
            <Typography variant="body2" className={classes.ingredientsExtras}>
                {"Glass: " + (recipe.glass.charAt(0).toUpperCase() + recipe.glass.substr(1))}
            </Typography>
            {isEditable && (
                <>
                    <IconButton color={"secondary"} onClick={() => setIsEditing(true)}>
                        <Edit/>
                    </IconButton>
                    <Dialog open={isEditing} onClose={handleClose} fullWidth>
                        <DialogTitle>{"Edit Glass"}</DialogTitle>
                        <DialogContent>
                            <div>
                                <TextField multiline fullWidth
                                           defaultValue={recipe.glass}
                                           color="secondary"
                                           onChange={e => setEditedGlass(e.target.value)}
                                           variant="outlined"/>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>
                                {"Cancel"}
                            </Button>
                            <Button onClick={handleSave} color="secondary">
                                {"Save"}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            )}
        </Box>
    );
}

export default RecipeGlass;