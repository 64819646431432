import * as React from 'react';
import {useState} from 'react';
import IconButton from "@material-ui/core/IconButton";
import {DeleteRounded} from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import {useMutation} from "@apollo/react-hooks";
import {client, GET_DEFAULT_CACHE_DATA, getDefaultCacheData} from "../ApolloLayer";
import {DELETE_RECIPE} from "../utilities/Queries";
import {useNavigate} from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {Button, Typography} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";

export const DeleteRecipeButton = ({recipe}) => {
    const navigate = useNavigate();
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const [deleteRecipe] = useMutation(DELETE_RECIPE, {
        client: client
    });

    const handleOpenDialog = () => {
        setDeleteDialogOpen(true);
    }

    const handleDelete = () => {
        deleteRecipe({
            variables: {
                recipeId: recipe.id
            },
            onCompleted: () => {
                client.cache.evict({id: "ROOT_QUERY", fieldName: "recipes"});
                client.cache.evict({id: "ROOT_QUERY", fieldName: "searchItems"});
                client.cache.writeQuery({
                    query: GET_DEFAULT_CACHE_DATA,
                    data: getDefaultCacheData()
                });

                client.cache.gc();
                navigate("/");
            }
        }).catch(error => {
            console.error("Error deleting recipe:", error);
        });
    }

    return (
        <>
            <Tooltip title={"Delete '" + recipe.name + "'"}>
                <IconButton style={{color: "red", marginLeft: "8px"}} onClick={handleOpenDialog}>
                    <DeleteRounded/>
                </IconButton>
            </Tooltip>
            <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
                <DialogTitle>{"Are you sure?"}</DialogTitle>
                <DialogContent>
                    <Typography>{"This will permanently delete '" + recipe.name + "'."}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)}>{"Cancel"}</Button>
                    <Button onClick={handleDelete} color="secondary">{"Delete"}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};