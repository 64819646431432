import React, {JSX} from "react";
import {useQuery} from "@apollo/react-hooks";
import {LOAD_SEARCH_TERMS} from "../utilities/Queries";
import RecipesInner from "./RecipesInner";
import {client} from "../ApolloLayer";


export default function Recipes(props): JSX.Element {
    const {
        loading,
        error,
        data: {
            searchTerms,
            selectedSortingStrategy,
            selectedGlasses,
            selectedBuildTypes,
            selectedTypes,
            selectedCocktailCategories,
            selectedBooks,
            minMaxIngredients,
            minMaxABV,
            minMaxAlcoholicUnits
        }
    } = useQuery(LOAD_SEARCH_TERMS, {
        client: client
    });
    if (loading) {
        return <div/>;
    }
    if (error) {
        console.log(error);
        return <div>{error.message}</div>
    }
    let key = selectedBuildTypes.data.reduce((acc, item) => {
            return acc + "-" + item;
        }, "")
        + selectedTypes.data.reduce((acc, item) => {
            return acc + "-" + item;
        }, "")
        + selectedGlasses.data.reduce((acc, item) => {
            return acc + "-" + item;
        }, "")
        + selectedBooks.data.reduce((acc, item) => {
            return acc + "-" + item;
        }, "")
        + selectedCocktailCategories.data.reduce((acc, item) => {
            return acc + "-" + item;
        }, "");
    return <RecipesInner key={key}
                         isLoggedIn={props.isLoggedIn}
                         searchTerms={searchTerms}
                         selectedSortingStrategy={selectedSortingStrategy}
                         selectedGlasses={selectedGlasses.data}
                         selectedBuildTypes={selectedBuildTypes.data}
                         selectedTypes={selectedTypes.data}
                         selectedCocktailCategories={selectedCocktailCategories.data}
                         selectedBooks={selectedBooks.data}
                         minMaxIngredients={minMaxIngredients}
                         minMaxABV={minMaxABV}
                         minMaxAlcoholicUnits={minMaxAlcoholicUnits}
    />;
}