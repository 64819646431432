import React, {useState} from "react";
import {Box, MenuItem, TextField, Typography} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {InfoOutlined} from "@material-ui/icons";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Tooltip from "@material-ui/core/Tooltip";
import {SAVE_MIXING_TECHNIQUE} from "../utilities/Queries";
import {useMutation} from "@apollo/client";
import {client} from "../ApolloLayer";

interface RecipeTotalVolumeProps {
    classes: any;
    recipe: any;
    differenceBetweenDefaultServingsAndSelected: number;
    isEditable: boolean;
}

const RecipeTotalVolume: React.FC<RecipeTotalVolumeProps> = ({
                                                                 classes,
                                                                 recipe,
                                                                 differenceBetweenDefaultServingsAndSelected,
                                                                 isEditable
                                                             }) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [saveMixingTechnique] = useMutation(SAVE_MIXING_TECHNIQUE, {
        client: client
    });

    const onClose = () => {
        setDialogOpen(false);
    }
    if (recipe.postDilutionVolume === 0) {
        return undefined;
    }
    return (
        <Box style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
            <Typography variant="body2" className={classes.ingredientsExtras}>
                {"Total volume: " + (recipe.postDilutionVolume * differenceBetweenDefaultServingsAndSelected / 29.5735).toFixed(1) + " oz."}
            </Typography>
            <Tooltip title={"How we calculate the total volume"}>
                <IconButton color={"secondary"} onClick={() => setDialogOpen(true)}>
                    <InfoOutlined/>
                </IconButton>
            </Tooltip>
            <Dialog open={dialogOpen} onClose={onClose} fullWidth>
                <DialogTitle>{"How we calculate the total volume"}</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        {buildDescription(recipe)}
                    </Typography>
                    {isEditable && (
                        <TextField
                            select
                            style={{marginTop: "16px"}}
                            label="Mixing technique"
                            defaultValue={recipe.mixingTechnique}
                            helperText="Choose the mixing technique"
                            onChange={(e) => {
                                saveMixingTechnique({
                                    variables: {
                                        recipeId: recipe.id,
                                        mixingTechnique: e.target.value
                                    }
                                });
                            }}
                        >
                            {mixingTechniques.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    )}
                </DialogContent>
            </Dialog>
        </Box>
    );
};

function buildDescription(recipe: any) {
    const mixingTechnique = recipe.mixingTechnique;
    const totalVolume = recipe.postDilutionVolume;
    const preDilutionVolume = recipe.preDilutionVolume;
    const difference = totalVolume - preDilutionVolume;

    // Calculate percentageAdded by the percentage of the total volume that the difference is
    const percentageAdded = ((difference / totalVolume) * 100).toFixed(0);

    if (mixingTechnique === "STIR") {
        return `This cocktail is being stirred over ice, which dilutes the cocktail as it is cooled down. ${percentageAdded}% of the total volume is water.`;
    }

    if (mixingTechnique === "SHAKE") {
        return `This cocktail is being shaken with ice, which dilutes the cocktail significantly. ${percentageAdded}% of the total volume is water.`;
    }
    if (mixingTechnique === "BUILD") {
        return `This cocktail is being built in a glass over ice, which dilutes the cocktail as the ingredients are cooled down. ${percentageAdded}% of the total volume is water.`;
    }
    if (mixingTechnique === "BLEND") {
        return `This cocktail is being blended, which dilutes the cocktail significantly. ${percentageAdded}% of the total volume is water.`;
    }
    if (mixingTechnique === "HOT") {
        return `This cocktail is being served hot.`;
    }
    if (mixingTechnique === "BLAZE") {
        return `This cocktail is set on fire, which reduces the volumes by about 20%.`;
    }
    if (mixingTechnique === "NEAT") {
        return `This cocktail is being served neat, without ice or water.`;
    }
    if (mixingTechnique === "SWIZZLE") {
        return `This cocktail is being swizzled, which dilutes the cocktail as it is mixed. ${percentageAdded}% of the total volume is water.`;
    }
    if (mixingTechnique === "FLASH_BLEND") {
        return `This cocktail is being flash blended, which dilutes the cocktail significantly. ${percentageAdded}% of the total volume is water.`;
    }

    return `This cocktail is being made using the ${mixingTechnique} technique. ${percentageAdded}% of the total volume is water.`;
}


const mixingTechniques = [
    {
        value: "STIR",
        label: "Stir"
    },
    {
        value: "SHAKE",
        label: "Shake"
    },
    {
        value: "BUILD",
        label: "Build"
    },
    {
        value: "BLEND",
        label: "Blend"
    },
    {
        value: "HOT",
        label: "Hot"
    },
    {
        value: "BLAZE",
        label: "Blaze"
    },
    {
        value: "NEAT",
        label: "Neat"
    },
    {
        value: "SWIZZLE",
        label: "Swizzle"
    },
    {
        value: "FLASH_BLEND",
        label: "Flash Blend"
    }
];

export default RecipeTotalVolume;