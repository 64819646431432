import React, {useState} from "react";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {
    ADD_TAG,
    CHANGE_DEFAULT_SERVINGS,
    DELETE_TAG,
    GET_RECIPE_DESCRIPTION,
    GET_RECIPE_FULL,
    SAVE_COMMENT
} from "../utilities/Queries";
import "./FullRecipe.css";
import {Box, CircularProgress} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import RedesignFullRecipeMobile from "./RedesignFullRecipeMobile";
import RedesignFullRecipeLarge from "./RedesignFullRecipeLarge";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import {AddCircleOutline, Edit, RemoveCircleOutline, Save} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import {client} from "../ApolloLayer";
import Tooltip from "@material-ui/core/Tooltip";
import RecipeIngredient from "./RecipeIngredient";
import AddIngredient from "./AddIngredient";
import RecipeGarnish from "./RecipeGarnish";
import RecipeGlass from "./RecipeGlass";
import RecipeTotalVolume from "./RecipeTotalVolume";

export default function FullRecipeContent(props): JSX.Element {
    const isLargeScreen = useMediaQuery("(min-width:950px)");
    let getRecipeFullVariables = props.getRecipeFullVariables;
    const {loading, error, data} = useQuery(GET_RECIPE_FULL, {
        variables: getRecipeFullVariables,
        client: client
    });
    const isUserCreatedRecipe = getRecipeFullVariables.recipeId !== undefined;
    const {
        loading: descriptionLoading,
        error: descriptionError,
        data: descriptionData
    } = useQuery(GET_RECIPE_DESCRIPTION, {
        variables: getRecipeFullVariables,
        client: client,
        skip: isUserCreatedRecipe
    });
    const [deleteTag] = useMutation(DELETE_TAG, {
        client: client
    });
    const [addTag] = useMutation(ADD_TAG, {
        client: client
    });
    const [saveComment] = useMutation(SAVE_COMMENT, {
        client: client
    });

    if (loading) {
        return <div style={{minHeight: "inherit", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <CircularProgress color="secondary"/>
        </div>;
    }
    if (error) {
        console.log(error);
        return <div>{"An error occurred"}</div>;
    }
    let recipe = data.recipe;
    let allTags = data.tags;

    const isEditable = recipe.userCreated && !recipe.isLocked;

    if (isLargeScreen) {
        return <RedesignFullRecipeLarge recipe={recipe}
                                        isLoggedIn={props.isLoggedIn}
                                        isEditable={isEditable}
                                        isUserCreatedRecipe={recipe.userCreated}
                                        deleteTag={deleteTag}
                                        addTag={addTag}
                                        saveComment={saveComment}
                                        getRecipeFullVariables={getRecipeFullVariables}
                                        allTags={allTags}
                                        recipeDescription={isUserCreatedRecipe ? null : descriptionData?.recipe.description}
        />;
    } else {
        return <RedesignFullRecipeMobile recipe={recipe}
                                         isLoggedIn={props.isLoggedIn}
                                         isEditable={isEditable}
                                         isUserCreatedRecipe={recipe.userCreated}
                                         deleteTag={deleteTag}
                                         addTag={addTag}
                                         saveComment={saveComment}
                                         getRecipeFullVariables={getRecipeFullVariables}
                                         allTags={allTags}
                                         recipeDescription={isUserCreatedRecipe ? null : descriptionData?.recipe.description}
        />;
    }
}

export function renderIngredients(recipe, classes, isEditable, isMobile, ingredientDescriptions) {
    const [howMany, setHowMany] = useState(recipe.servings);
    const differenceBetweenDefaultServingsAndSelected = howMany / recipe.servings;
    return <div className={isMobile ? "ingredients-mobile" : "ingredients-full"}>
        <div className="flex-column-center" style={{marginBottom: "8px"}}>
            <Typography color="secondary" variant="h6" className={classes.ingredients}>{"Ingredients"}</Typography>
            {renderIngredientMultiplier(recipe, howMany, setHowMany, isEditable, isMobile)}
        </div>
        {recipe.ingredients.map((ingredient, i) => {
            const matchingIngredientDescription = ingredientDescriptions ? ingredientDescriptions.find((ingredientDescription) => {
                return ingredientDescription.ingredientId === ingredient.id;
            }) : undefined;
            return <RecipeIngredient key={i} recipe={recipe} ingredient={ingredient} howMany={howMany} classes={classes}
                                     ingredientDescription={matchingIngredientDescription} i={i}
                                     isEditable={isEditable}/>;
        })}
        {isEditable && <AddIngredient recipe={recipe}/>}
        <RecipeGarnish recipe={recipe} classes={classes} isEditable={isEditable}/>
        <RecipeGlass recipe={recipe} classes={classes} isEditable={isEditable}/>
        <RecipeTotalVolume
            classes={classes}
            recipe={recipe}
            differenceBetweenDefaultServingsAndSelected={differenceBetweenDefaultServingsAndSelected}
            isEditable={isEditable}
        />
    </div>;
}

function renderIngredientMultiplier(recipe, howMany, setHowMany, isEditable, isMobile) {
    const [userServingsDialogOpen, setUserServingsDialogOpen] = useState(false);
    const [userServings, setUserServings] = useState(recipe.servings);
    const [iconDisabled, setIconDisabled] = useState(false);
    const [changeDefaultServings] = useMutation(CHANGE_DEFAULT_SERVINGS, {
        client: client,
    });
    const handleEditServing = () => {
        if (userServingsDialogOpen) {
            setIconDisabled(true);
            changeDefaultServings({
                variables: {
                    recipeId: recipe.id,
                    servings: userServings
                }
            }).then(() => {
                setUserServingsDialogOpen(false);
                setHowMany(userServings);
                setIconDisabled(false);
            });
        } else {
            setUserServingsDialogOpen(true);
        }
    };

    return <div className="ingredient-multiplier">
        <IconButton color="secondary" aria-label="Less"
                    disabled={userServingsDialogOpen ? userServings === 1 : howMany === 1}
                    onClick={() => {
                        if (userServingsDialogOpen) {
                            setUserServings(userServings - 1);
                        } else {
                            setHowMany(howMany - 1);
                        }
                    }}>
            <RemoveCircleOutline/>
        </IconButton>
        <Box style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
            {userServingsDialogOpen ? (
                <TextField
                    type="number"
                    value={userServings}
                    color="secondary"
                    onChange={(event) => {
                        const newValue = event.target.value;
                        setUserServings(newValue);
                    }}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            handleEditServing();
                        }
                    }}
                />
            ) : <Typography color="secondary"
                            variant={isMobile ? "body2" : "body1"}>{howMany + " " + (howMany === 1 ? "Serving" : "Servings")}</Typography>}
            {isEditable && (
                <Tooltip title="Change default servings" placement="top">
                    <IconButton size={"small"}
                                style={{marginLeft: "8px"}}
                                color="secondary"
                                aria-label="Edit"
                                disabled={iconDisabled}
                                onClick={handleEditServing}>
                        {!userServingsDialogOpen ? <Edit style={{fontSize: "20px"}}/> :
                            <Save style={{fontSize: "20px"}}/>}
                    </IconButton>
                </Tooltip>
            )}
        </Box>
        <IconButton color="secondary" aria-label="More" onClick={() => {
            if (userServingsDialogOpen) {
                setUserServings(userServings + 1);
            } else {
                setHowMany(howMany + 1);
            }
        }}>
            <AddCircleOutline/>
        </IconButton>
    </div>;
}