import * as React from 'react';
import {useState} from 'react';
import IconButton from "@material-ui/core/IconButton";
import {Close, Edit, Save} from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import {useMutation} from "@apollo/react-hooks";
import {client} from "../ApolloLayer";
import {EDIT_RECIPE_TITLE} from "../utilities/Queries";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import {Button, DialogContent, TextField} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";

export const EditRecipeTitle = ({recipe}) => {
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [text, setText] = useState(recipe.name);
    const [editRecipeTitle] = useMutation(EDIT_RECIPE_TITLE, {
        client: client
    });
    const isLargeScreen = useMediaQuery("(min-width:950px)");

    const onClose = () => {
        setEditDialogOpen(false);
        setText(recipe.name);
    }

    const save = () => {
        setEditDialogOpen(false);
        editRecipeTitle({
            variables: {
                recipeId: recipe.id,
                title: text
            },
            optimisticResponse: {
                __typename: "Mutation",
                editRecipeTitle: {
                    __typename: "Recipe",
                    id: recipe.id,
                    name: text
                }
            }
        }).catch(error => {
            console.error("Error editing recipe title:", error);
        });
    }

    return (
        <>
            <Tooltip title={"Edit '" + recipe.name + "' title"}>
                <IconButton color={"secondary"} style={{marginLeft: "8px"}} onClick={() => setEditDialogOpen(true)}>
                    <Edit/>
                </IconButton>
            </Tooltip>
            <Dialog open={editDialogOpen} fullScreen={!isLargeScreen} onClose={onClose} fullWidth>
                {!isLargeScreen && <AppBar color="secondary" style={{position: "relative"}}>
                    <Toolbar>
                        <div className={"flex-row-space-between"}>
                            <div className={"flex-row-center"}>
                                <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                                    <Close color="primary"/>
                                </IconButton>
                                <Typography color="primary" variant="h6">
                                    {"Edit recipe title"}
                                </Typography>
                            </div>
                            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                                <Save color="primary"/>
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>}
                {isLargeScreen && <DialogTitle>{"Edit recipe title"}</DialogTitle>}
                <DialogContent>
                    <TextField
                        autoFocus
                        color={"secondary"}
                        margin="dense"
                        id="title"
                        label="Title"
                        type="text"
                        fullWidth
                        value={text}
                        onChange={(event) => setText(event.target.value)}
                    />
                </DialogContent>
                {isLargeScreen && (
                    <DialogActions>
                        <Button onClick={onClose}>
                            {"Cancel"}
                        </Button>
                        <Button onClick={save} color="secondary">
                            {"Save"}
                        </Button>
                    </DialogActions>
                )}
            </Dialog>
        </>
    );
};