import React from 'react';
import {AppBar, Dialog, DialogContent, IconButton, Toolbar, Typography} from "@material-ui/core";
import {Close} from "@material-ui/icons";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DialogTitle from "@material-ui/core/DialogTitle";

type Props = {
    open: boolean;
    onClose: () => void;
    description: string;
    recipeName: string;
};

function formatDescription(description: string) {
    if (!description) {
        return description;
    }
    const formattedDescription = replaceAll(description, ".", ".<br /><br />");
    const parts = formattedDescription.split(/(\*[^*]+\*)/g);
    return parts.map((part, index) => {
        if (part.startsWith('*') && part.endsWith('*')) {
            return `<strong key=${index}>${part.slice(1, -1)}</strong>`;
        }
        return part;
    }).join('');
}

function escapeRegExp(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

function replaceAll(str, find, replace) {
    return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

export const CocktailDescriptionDialog = ({open, onClose, description, recipeName}: Props) => {
    const isLargeScreen = useMediaQuery("(min-width:950px)");

    return (
        <Dialog open={open} fullScreen={!isLargeScreen} onClose={onClose}>
            {!isLargeScreen && (
                <AppBar color="secondary" style={{position: "relative"}}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                            <Close color="primary"/>
                        </IconButton>
                        <Typography color="primary" variant="h6">
                            {recipeName}
                        </Typography>
                    </Toolbar>
                </AppBar>
            )}
            {isLargeScreen && (
                <DialogTitle id="form-dialog-title">{recipeName}</DialogTitle>
            )}
            <DialogContent>
                <Typography variant="body1" style={{
                    lineHeight: 1.75,
                    fontFamily: "ui-sans-serif,-apple-system,system-ui,Segoe UI,Helvetica,Apple Color Emoji,Arial,sans-serif,Segoe UI Emoji,Segoe UI Symbol",
                }} dangerouslySetInnerHTML={{__html: formatDescription(description)}}>
                </Typography>
            </DialogContent>
        </Dialog>
    );
};