import React, {useState} from "react";
import {Typography} from "@material-ui/core";

interface RecipeInstructionProps {
    instruction: string;
    index: number;
}

const RecipeInstruction: React.FC<RecipeInstructionProps> = ({instruction, index}) => {
    const [completed, setCompleted] = useState(false);

    if (instruction.trim() === "") {
        return null;
    }

    return (
        <div key={"instr-" + instruction + "-" + index} className={index === 0 ? "first-instruction" : "instruction"}>
            <div className="number">{index + 1}</div>
            <Typography
                variant="body2"
                onClick={() => {
                    setCompleted(!completed);
                }}
                style={completed ? {textDecoration: "line-through", cursor: "pointer"} : {cursor: "pointer"}}
            >
                {instruction + "."}
            </Typography>
        </div>
    );
};

export default RecipeInstruction;