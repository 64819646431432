import * as React from 'react';
import {
    AppBar,
    Box,
    DialogContentText,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Toolbar,
    Tooltip,
    Typography
} from "@material-ui/core";
import {AccountTreeOutlined, Add, ArrowBack, Close} from "@material-ui/icons";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {CREATE_NEW_PANTRY_PROFILE, GET_PANTRY_PROFILES} from "../../utilities/Queries";
import {client} from "../../ApolloLayer";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {SingleProfileView} from './SingleProfileView';

export interface PantryProfile {
    id: string;
    title: string;
    ingredients: { id: string; name: string }[];
    active: boolean;
    activeUntil: string | null;
}

export const PantryProfiles = () => {
    const {loading, error, data} = useQuery(GET_PANTRY_PROFILES, {
        client: client,
    });
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [currentStep, setCurrentStep] = React.useState(0);
    const [selectedProfileId, setSelectedProfileId] = React.useState<string | null>(null);
    const isLargeScreen = useMediaQuery("(min-width:950px)");

    const [createNewPantryProfile] = useMutation(CREATE_NEW_PANTRY_PROFILE, {
        client: client,
        update(cache, {data: {createPantryProfile}}) {
            const existingProfiles: any = cache.readQuery({query: GET_PANTRY_PROFILES});
            cache.writeQuery({
                query: GET_PANTRY_PROFILES,
                data: {
                    pantryProfiles: [
                        ...existingProfiles.pantryProfiles,
                        createPantryProfile,
                    ],
                },
            });
        },
        optimisticResponse: ({title}) => ({
            createPantryProfile: {
                __typename: "PantryProfile",
                id: Math.random().toString(36).substr(2, 9),
                title: title,
                ingredients: [],
                active: false,
                activeUntil: null
            },
        }),
    });

    const handleCreateNewProfile = () => {
        let newTitle = prompt("Enter a title for the new list of temporary ingredients");
        newTitle = newTitle ? newTitle.trim() : null;
        if (!newTitle) return;
        createNewPantryProfile({
            variables: {title: newTitle},
        });
    };

    const handleListItemClick = (profileId: string) => {
        setSelectedProfileId(profileId);
        setCurrentStep(1);
    };

    const handleBack = () => {
        setCurrentStep(0);
        setSelectedProfileId(null);
    };

    const selectedProfile = data?.pantryProfiles.find((profile: PantryProfile) => profile.id === selectedProfileId);

    if (loading) return <Dialog open={dialogOpen}><DialogTitle>{"Loading..."}</DialogTitle></Dialog>;
    if (error) {
        console.log(error);
        return <Dialog open={dialogOpen}><DialogTitle>{"Error loading list of temporary ingredients"}</DialogTitle></Dialog>
    }

    return (
        <>
            <Tooltip title="Manage temporary ingredients">
                <IconButton edge="end" onClick={() => {
                    setDialogOpen(true);
                }}>
                    <AccountTreeOutlined color={"secondary"}/>
                </IconButton>
            </Tooltip>
            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth="sm" fullWidth
                    fullScreen={!isLargeScreen}>
                {currentStep === 1 && selectedProfile && (
                    <SingleProfileView profile={selectedProfile} onBack={handleBack}/>
                )}
                {currentStep === 0 && (
                    <>
                        {!isLargeScreen && (
                            <AppBar color="secondary" style={{position: "relative"}}>
                                <Toolbar>
                                    {currentStep !== 0 && (
                                        <Tooltip title="Back">
                                            <IconButton edge="start" color="inherit" onClick={handleBack}
                                                        aria-label="back">
                                                <ArrowBack color="primary"/>
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    <Tooltip title="Close">
                                        <IconButton edge="start" color="inherit"
                                                    onClick={() => setDialogOpen(false)}
                                                    aria-label="close">
                                            <Close color="primary"/>
                                        </IconButton>
                                    </Tooltip>
                                    <Typography color="primary" variant="h6" style={{flexGrow: 1}}>
                                        {"Temporary ingredients"}
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                        )}
                        {isLargeScreen &&
                            <DialogTitle>
                                <Box display="flex" alignItems="center" justifyContent="space-between">
                                    <Typography variant="h6">
                                        {"Temporary ingredients"}
                                    </Typography>
                                </Box>
                            </DialogTitle>}
                        <DialogContent>
                            <DialogContentText>
                                {"Temporary ingredients allows you to temporarily expand your owned ingredients with temporary additions. You can use this for your party supplies (sparkling wine for New Year's Eve), if you've made a special syrup or in any situation where you don't want to expand your permanent ingredient list."}
                            </DialogContentText>
                            <List>
                                <ListItem button onClick={handleCreateNewProfile}>
                                    <ListItemIcon>
                                        <Add/>
                                    </ListItemIcon>
                                    <ListItemText primary={"Create new list"}/>
                                </ListItem>

                                {data.pantryProfiles.map((profile: PantryProfile) => (
                                    <ListItem key={profile.id} button
                                              onClick={() => handleListItemClick(profile.id)}>
                                        <ListItemText primary={profile.title + " (" + profile.ingredients.length + " ingredients)"}/>
                                        <Typography style={{opacity: profile.active ? 1 : 0.5}}
                                                    color={profile.active ? "secondary" : "inherit"}>
                                            {profile.active ? "Active" : "Inactive"}
                                        </Typography>
                                    </ListItem>
                                ))}
                            </List>
                        </DialogContent>
                    </>
                )}
            </Dialog>
        </>
    );
};