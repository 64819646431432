import React, {useState} from "react";
import {Divider, IconButton, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip,} from "@material-ui/core";
import {
    AccountTreeOutlined,
    CheckCircle,
    InfoOutlined,
    MoreVert,
    StarBorderOutlined,
    StarOutlined,
} from "@material-ui/icons";
import {useMutation} from "@apollo/react-hooks";
import {GET_ALL_INGREDIENTS, INVERT_INGREDIENT, INVERT_PRIORITIZE_INGREDIENT_USAGE,} from "../../utilities/Queries";
import {client} from "../../ApolloLayer";
import BottlesView from "./BottlesView";
import {IngredientPantryProfileDialog} from "./IngredientPantryProfileDialog";
import {useLoading} from "../IngredientLoadingProvider";

type RecipeCount = {
    total: number;
    canMake: number;
    lastIngredientMissing?: number;
};

export type Ingredient = {
    name: string;
    owned: boolean;
    prioritized?: boolean;
    recipeCount: RecipeCount;
    bottles: Array<any>;
};

type SingleIngredientProps = {
    ingredient: Ingredient;
};

const SingleIngredient: React.FC<SingleIngredientProps> = ({ingredient}) => {
    const [displayPossibleBottles, setDisplayPossibleBottles] = useState<Ingredient | false>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [pantryDialogOpen, setPantryDialogOpen] = useState(false);
    const {loading, setLoading} = useLoading();

    const [invertIngredient] = useMutation(INVERT_INGREDIENT, {
        client: client,
        refetchQueries: [{query: GET_ALL_INGREDIENTS}],
        onQueryUpdated: (observableQuery) => {
            return observableQuery
                .refetch()
                .then(() => setLoading(false));
        },
    });
    const [invertPrioritizeIngredientUsage] = useMutation(INVERT_PRIORITIZE_INGREDIENT_USAGE, {
        client: client,
        refetchQueries: [{query: GET_ALL_INGREDIENTS}],
        onCompleted: () => setLoading(false),
    });

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleAction = (action: string) => {
        setLoading(true);
        if (action === "info" && ingredient.bottles.length > 0) {
            setDisplayPossibleBottles(ingredient);
        } else if (action === "prioritize") {
            invertPrioritizeIngredientUsage({
                variables: {ingredient: ingredient.name},
            });
        } else if (action === "toggleOwned") {
            invertIngredient({
                variables: {ingredient: ingredient.name},
            });
        } else if (action === "pantryProfiles") {
            setPantryDialogOpen(true);
        }
        handleMenuClose();
    };

    const handlePantryDialogClose = () => {
        setPantryDialogOpen(false);
    };

    const listItemText = ingredient.owned
        ? `${ingredient.name} (Used in ${ingredient.recipeCount.total} recipes and ${ingredient.recipeCount.canMake} recipes you can make)`
        : `${ingredient.name} (Used in ${ingredient.recipeCount.total} recipes and finishes ${ingredient.recipeCount.lastIngredientMissing} recipes)`;

    return (
        <>
            <ListItem>
                <Tooltip title="Toggle owned status">
                    <ListItemIcon>
                        <IconButton
                            edge="start"
                            onClick={() => handleAction("toggleOwned")}
                            aria-label="toggle-owned"
                            disabled={loading}
                        >
                            <CheckCircle color={ingredient.owned ? "secondary" : "inherit"}/>
                        </IconButton>
                    </ListItemIcon>
                </Tooltip>
                <ListItemText>{listItemText}</ListItemText>

                <ListItemIcon style={{minWidth: 0}}>
                    <IconButton edge="end" onClick={handleMenuClick} aria-label="menu" disabled={loading}>
                        <MoreVert/>
                    </IconButton>
                </ListItemIcon>
            </ListItem>

            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                {/* Info Action */}
                {ingredient.bottles.length > 0 && (
                    <MenuItem onClick={() => handleAction("info")}>
                        <ListItemIcon>
                            <InfoOutlined fontSize="small"/>
                        </ListItemIcon>
                        {"View Possible Bottles"}
                    </MenuItem>
                )}

                {/* Prioritize Action */}
                {ingredient.owned && (
                    <MenuItem onClick={() => handleAction("prioritize")}>
                        <ListItemIcon>
                            {ingredient.prioritized ? (
                                <StarOutlined color="secondary" fontSize="small"/>
                            ) : (
                                <StarBorderOutlined fontSize="small"/>
                            )}
                        </ListItemIcon>
                        {"Prioritize using this ingredient"}
                    </MenuItem>
                )}

                {/* Pantry Profiles Action */}
                {!ingredient.owned && (<MenuItem onClick={() => handleAction("pantryProfiles")}>
                        <ListItemIcon>
                            <AccountTreeOutlined fontSize="small"/>
                        </ListItemIcon>
                        {"Add temporarily"}
                    </MenuItem>
                )}
            </Menu>
            <Divider/>

            {displayPossibleBottles && (
                <BottlesView ingredient={ingredient} close={() => setDisplayPossibleBottles(false)}/>
            )}

            {/* Pantry Profiles Dialog */}
            {!ingredient.owned && <IngredientPantryProfileDialog ingredient={ingredient} open={pantryDialogOpen}
                                                                 onClose={handlePantryDialogClose}/>}
        </>
    );
};

export default SingleIngredient;